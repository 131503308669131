<template>
    <main>
        <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
        <page-header-compact>Historial de historias Clinicas</page-header-compact>
        <div class="container-xl px-4 mt-n15">
            <div class="card mt-2">
                <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
            </div>
            <div class="card mt-2">
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <tr class="table-primary">
                                <th>Tipo de historia</th>
                                <th>Fecha</th>
                                <th></th>
                            </tr>
                            <tr v-for="item in historias" :key="item.id">
                                <td>{{item.tipo_historia.nombre}}</td>
                                <td>{{item.fecha}}</td>
                                <td>
                                    <button
                                    @click="imprimir(item.id)"
                                        class="btn btn-success btn-sm"
                                        >
                                        Ver historia  <i class="fas fa-eye fa-fw"></i>
                                        </button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>

import modalInformacionUsuario from "../../base/informacionUsuario/modalInformacionUsuario";
import cardInformacionUsuario from "../../base/informacionUsuario/cardInformacionUsuario";
import PageHeaderCompact from "../../../../components/layouts/content/page-header-compact";
import usuarioService from '../../../../services/usuarioService';
import historiaClinicaService from '../../../../services/historiaClinicaService';

export default {
    components: {modalInformacionUsuario,cardInformacionUsuario,PageHeaderCompact},
    data(){
        return {
            usuario: {},
            historias: [],
            rutas_imprimir:{
                'I':'historias.infancia.print',
                'EMB':'historias.embarazo.print',
                'AD':'historias.adolescente.print',
                'JUV':'historias.juventud.print',
                'ADU':'historias.adulto.print',
            }
        }
    },
    methods: {
        async cargarUsuario(id) {
            const response = await usuarioService.show(id);
            this.usuario = response.data;
        },
        async cargarHistorias(id){
            const response = await historiaClinicaService.showByIdUser(id);
            this.historias= response.data;
        },
        imprimir(id){
            window.open(historiaClinicaService.print(id));
            //this.$router.push({name : this.rutas_imprimir[tipo], params:{id:id}});
        }
    },
    async created(){
        await this.cargarUsuario(this.$route.params.id);
        await this.cargarHistorias(this.$route.params.id);
    }
}
</script>